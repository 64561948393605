@import "utils/styles/mixins.scss";
@import "letrus-ui/dist/styles/letrusTheme";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 17rem;
  background: transparent;
  padding: 0.5rem;

  p {
    @include paragraph5();
  }
}

.outlined {
  background: $white;
  border: $border-2 $medium-grey;
  border-radius: $border-radius-2;
}

.actionButtons {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  button {
    cursor: pointer;
    background: transparent;
    border: $border-1 $medium-grey;
    border-radius: $border-radius-3;
    padding: 0.5rem;

    svg {
      width: 1.125rem;
      height: 1.125rem;
    }
  }

  button:disabled {
    cursor: default;
  }
}

.like {
  color: $primary-blue;

  &.active {
    color: $white;
    background: $primary-blue;
    border-color: $primary-blue;
  }
}

.dislike {
  color: $primary-red;

  &.active {
    color: $white;
    background: $primary-red;
    border-color: $primary-red;
  }
}
