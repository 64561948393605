@import "utils/styles/mixins.scss";
@import "letrus-ui/dist/styles/letrusTheme";

.tooltipOverlay {
  color:  $black;
  animation: fade-in-bounce $animation-base-duration $bounce-over-out-easing;
  transition: all 0.4 ease-out;
  will-change: opacity transform;

  a {
    font-weight: bold;
    text-decoration: underline;
  }
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 0.5rem;

  span {
    @include paragraph5();
  }

  p {
    @include paragraph6();
    margin: 0rem;
  }
}

.tooltipBody {
  max-height: 85vh;

  .rc-tooltip-inner {
    padding: 0 !important;
  }

  p {
    margin-bottom: 0;
  }
}

.tooltipSection {
  padding: 0.5rem;
  @include paragraph6();
}

.tooltipSectionTitle {
  @include paragraph5(); 
}

.reviewRate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;

  p {
    margin-bottom: 0.5rem;
  }

  hr {
    width: 100%;
    border-inline-width: 0rem;
    border-block-width: 0rem;
    border-bottom: $border-2 $medium-grey;
  }
}

